import React from 'react'

class Scroller extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      progress: 0,
    }
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = ev => {
    const pct = 100 * window.scrollY / (document.body.clientHeight - window.innerHeight);
    this.setState({
      progress: pct
    });
  };

  render() {
    const { progress } = this.state
    return (
      <div style={{
        background: 'rgba(255, 255, 255, 0.5)',
        position: 'fixed',
        marginTop: '0',
        width: '100%',
        height: '2px',
        zIndex: '999',
      }} className="headerScroller">
        <div style={{
          width: `${progress}`+'%',
          height: '100%',
          background: 'var(--accent-color)',
        }} />
      </div>
    )
  }
}

export default Scroller
