import React from "react"
import Header from '../components/ui/header'
import Footer from '../components/ui/footer'
import Scroller from '../components/ui/scroller'

import { StaticQuery, graphql } from "gatsby"

export default ({children}) => (
<StaticQuery
  query={graphql`
    query LayoutScrollQuery {
      site {
        siteMetadata {
          title,
          subtitle,
          author,
          social {
            twitter,
            instagram,
            gitlab,
            strava
          },
          description
        }
      }
    }
  `}
  render={data =>
    <>
      <Scroller />
      <Header site={data.site} />
      <div className="layout-content">
        {children}
      </div>
      <hr className="divider" style={{ margin: '2.5rem auto'}} />
      <Footer/>
    </>
  }
  />
)
